header {
  display: flex;
  justify-content: center;
  align-items: center;
}

header a {
  width: 250px;
}
header .logo {
  max-width: 100%;
}

.container {
  margin: 0;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat',sans-serif;
}

.wrapper {
  width: 500px;
  padding: 25px;
  max-width: 100%;
}
canvas {
  border: 1px solid #222;
  display: block;
  margin: 15px 0px;
  width: 100%;
}

textarea{
  width: calc(100% - 30px);
  padding: 15px;
  border: none;
  background-color: #eee;
  resize: vertical;
}

h1,h2,h3 {
  text-align: center;
  font-family: 'Merriweather', serif;
  font-weight: 900;
  margin-bottom: 5px;
}

.score {
  white-space: pre;
}

#date {
  font-size: 20px;
  font-family: 'Montserrat',sans-serif;
  text-align: center;
  margin-top: 0;
}

#pgn { 
  width: 90%;
  margin: 15px auto;
  font-family: 'Montserrat',sans-serif;
}

.row {
  width: 100%;
}

.row label {
  width: 50%;
  margin: 10px 0px;
  text-align: center;
  display: inline-block;
}

.row label input[type="color"] {
  width: 95%;
  display: block;
  margin: 0 auto;
}

label.full {
  width: 100% !important;
}


.controls .saveButton {
  display: block;
  margin: 15px auto;
  text-align: center;
  padding: 20px 25px;
  border: none;
  background-color: #3838a7;
  font-family: 'Montserrat',serif;
  font-size: 20px;
  color: #fff;
  transition: .3s all;
  cursor: pointer;
}

.controls .saveButton:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.controls .saveButton:hover {
  opacity: .9;
}

.message {
  width: fit-content;
  min-width: 300px;
  margin: 5px auto;
  padding: 5px;
  transition: .3s all;
}

.message a {
  color: inherit;
}
.message.warn {
  border: 2px solid #dede15;
  display: flex;
  background: #ffffde;
  border-radius: 5px;
  align-items: center;
  max-width: 100%;
}

.message.warn .material-icons {
  font-size: 28px;
  padding: 15px;
}
.message.success {
  border: 2px solid #999;
}
.message.success p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message.success p button {
  border: none;
  transition: .3s all;
  background-color: #3838a7;
  color: #fff;
  cursor: pointer;
  padding: 15px 20px;
}
.message.success p button:hover {
  opacity: .8;
}
.message.success p .material-icons {
  vertical-align: sub;
}

/*Switch CSS */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px !important;
  height: 34px !important;
  vertical-align: middle;
  margin: 10px !important;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Recent games css */

.cardContainer {
  display: flex;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.card {
  width: 300px;
  margin: 15px;
  border-radius: 18px;
  box-shadow: 0px 0px 8px -2px #000;
  overflow: hidden;
  padding-bottom: 0;
  transition: .3s all;
}

.card:hover {
  box-shadow: 0px 0px 12px -2px #000;
}

.card a {
  color: inherit;
  text-decoration: none;
}

.card h3 {
  font-family: 'Montserrat';
  margin: 15px;
}